import BayadCardRegular from "../../assets/services-page/afcs/Regular Card 1.png"
import BayadCardDiscounted from "../../assets/services-page/afcs/Discounted Card 1.png"
import PageBanner from "../../assets/services-page/afcs/AFCS Page Banner.png"
import Dash from "../../assets/services-page/afcs/remove-sharp.svg"
import AnalyticsIcon from "../../assets/services-page/afcs/analytics.svg"
import DistanceIcon from "../../assets/services-page/afcs/distance.svg"
import PaymentIcon from "../../assets/services-page/afcs/payment.svg"
import TicketIcon from "../../assets/services-page/afcs/ticket.svg"
import VerifiedIcon from "../../assets/services-page/afcs/verified.svg"

import { recorder, accessories } from "../../data/afcs.js"

import { useState } from "react"
import { NavLink } from "react-router-dom"

const AFCS = () => {

    const [cardActive, setCardActive] = useState(true)

    const handleCardToggle = () => {
        setCardActive(prev => !prev)
    }

    return (
        <>
            <div className="afcs">
                <div className="page-header">
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                        <img src={PageBanner} className="page-banner" alt="AFCS Banner" />
                        <h1 className='fw-bold text-center'>Automated Fare Collection System</h1>
                        {/* <div className="sub-heading">
                            <p className="m-0">
                                Euodoó Technologies’ Automated Fare Collection System (AFCS) is one of the main 
                                components of the Public Transport Modernization Program (PTMP). It aims to 
                                restructure and modernize public transportation, specifically jeepneys.
                            </p>
                        </div> */}
                    </div>
                </div>

                <div className="afcs-content container my-5">
                    <div className="feature-circle-container d-flex justify-content-center justify-content-lg-between mb-5 text-center flex-wrap">
                        <div className="feature-circle d-flex flex-column justify-content-start align-items-center">
                            <div className="circle-logo ">
                                <img src={VerifiedIcon} alt="Verified Icon" />
                            </div>
                            <div className="circle-title">
                                <p className="fw-bold">LTFRB Accredited</p>
                            </div>
                        </div>
                        <div className="feature-circle d-flex flex-column justify-content-start align-items-center">
                            <div className="circle-logo ">
                                <img src={DistanceIcon} alt="Distance Icon" />
                            </div>
                            <div className="circle-title">
                                <p className="fw-bold">Real-time distance-based fare computation</p>
                            </div>
                        </div>
                        <div className="feature-circle d-flex flex-column justify-content-start align-items-center">
                            <div className="circle-logo ">
                                <img src={PaymentIcon} alt="Payment Icon" />
                            </div>
                            <div className="circle-title">
                                <p className="fw-bold">Facilitates contactless payment</p>
                            </div>
                        </div>
                        <div className="feature-circle d-flex flex-column justify-content-start align-items-center">
                            <div className="circle-logo ">
                                <img src={AnalyticsIcon} alt="Analytics Icon" />
                            </div>
                            <div className="circle-title">
                                <p className="fw-bold">Generates relevant reports and analytics</p>
                            </div>
                        </div>
                        <div className="feature-circle d-flex flex-column justify-content-start align-items-center">
                            <div className="circle-logo ">
                                <img src={TicketIcon} alt="Ticket Icon" />
                            </div>
                            <div className="circle-title">
                                <p className="fw-bold">Efficient integrated ticketing system</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-md-row flex-column gap-3">
                        <div className="content-text">
                            <h3 className="fw-bold">Smart Payments, Smarter Transit</h3>
                            <p className="text-justify">
                                Bayadcard is the primary payment method for passengers using the Automated Fare Collection System (AFCS). 
                                It is available in two (2) types: the Regular card and Discounted card which is specifically designed for students, 
                                persons with disabilities (PWDs), and senior citizens. 
                            </p>
                            <p>
                                Moreover, the Bayadcard is available for white labeling, allowing organizations to customize the card for their
                                own branding and specific needs. 
                            </p>
                        </div>
                        <div className="content-card-slider d-flex justify-content-center align-items-center mx-auto">
                            <img src={BayadCardRegular} className={`img-fluid w-100 regular-card ${cardActive === true ? 'active' : ''}`} onClick={handleCardToggle} alt="BayadCard Regular" />
                            <img src={BayadCardDiscounted} className={`img-fluid w-100 discount-card ${cardActive === false ? 'active' : ''}`} onClick={handleCardToggle} alt="BayadCard Discounted" />
                            {/* {console.log(cardActive)} */}
                        </div>
                    </div>
                    

                    <div className="products-container my-5">
                        <h3 className="fw-bold text-center">More Than Just Payments – We Provide the Tools You Need!</h3>
                        
                        <div className="product-category">
                            <p className="product-category-title m-0 fw-semibold mt-5 text-center p-3 rounded mx-auto" style={{width: 'fit-content'}}>Offline Vehicle Video Recorder</p>
                            <div className="d-flex justify-content-around align-items-center flex-wrap">
                            {recorder.map((item, index) => (
                                <div key={index} className="product-box p-3">
                                    <div className="product-thumbnail">
                                        <img src={item.img} alt={item.name} className="img-fluid user-select-none" />
                                        <div className="product-name text-center fw-semibold p-2">
                                            <p className="m-0">{item.name}</p>
                                        </div>
                                    </div>
                                    <div className="product-specs p-2">
                                        <ul className="list-unstyled">
                                            {item.specs.map((spec, specIndex) => (
                                                <li key={specIndex}><img className="dash-bullet me-2 user-select-none" src={Dash} alt="-" />{spec}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}


                            </div>
                        </div>

                        <div className="product-category" style={{marginTop: "125px"}}>
                            <p className="product-category-title m-0 fw-semibold mt-5 text-center p-3 rounded mx-auto" style={{width: 'fit-content'}}>SD Card, CCTV Accessories, and WiFi</p>
                            <div className="d-flex justify-content-around align-items-center flex-wrap">
                                {accessories.map((item, index) => (
                                    <div className="product-box p-3" key={index}>
                                        <div className="product-thumbnail">
                                            <img src={item.img} className="img-fluid user-select-none"/>
                                            <div className="product-name text-center fw-semibold p-2"><p className="m-0">{item.name}</p></div>
                                        </div>
                                        <div className="product-specs p-2">
                                            <ul className="list-unstyled">
                                                {item.specs.map((spec, specIndex) => (
                                                    <li key={specIndex}><img className="dash-bullet me-2 user-select-none" src={Dash} alt="-" />{spec}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="text-center bg-dark text-light p-4">
                    <div className="container">
                            <h4 className="fw-bold">Transform Transit Payments Today!</h4>
                            <NavLink to='/contactus' className="btn home-cta fw-semibold">Try Our Solution</NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AFCS;