import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo/euodoo-white.png"



const Footer = ()=> {
    
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogoClick = () => {

        if (location.pathname === "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            navigate("/");
        }
    };
    return(
        <div id="footer" className="d-flex justify-content-center align-items-start flex-column p-4">
            <div className="container">
                <div className="d-flex justify-content-between flex-lg-row flex-column column-gap-4">
                    <div className="footer-image">
                        <div className="footer-brand" onClick={handleLogoClick} style={{cursor: "pointer"}}>
                            <img src={logo} className="image-fluid" alt="euodoo logo"/>
                        </div>
                    </div>
                        <div className="footer-nav d-flex gap-2 gap-md-5 flex-column flex-md-row">
                            <div className="footer-links">
                                <p className="text-white fw-bold mb-1">Our Profile</p>
                                <ul className="list-unstyled">
                                    <li>
                                        <NavLink to='/aboutus'>About Us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/careers'>Careers</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-links services">
                                <p className="text-white fw-bold mb-1">Services</p>
                                <ul className="list-unstyled">
                                    <li>
                                        <NavLink to='/services/AFCS'>Automated Fare Collection System</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/services/FMS'>Fleet Management System</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/services/VAPT'>Vulnerability Assessment and Penetration Testing</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/services/EMS'>Event Management System</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/services/euoride'>Taxi Booking App</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-links">
                                <p className="text-white fw-bold mb-1">Updates</p>
                                <ul className="list-unstyled">
                                    <li>
                                        <NavLink to='/news'>Updates</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className="links">
                                    <p className="text-white mb-1"><b>Reach Us</b></p>
                                </div>
                                <div className="d-flex flex-column">
                                    <p className="text-white m-0 flex-wrap">Email: customercare@euodoo.com.ph</p>
                                    <p className="text-white">Tel: +632-7088-7101 +63-960-471-9671 
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="links">
                    <p className="text-white"><b>Follow us</b></p>
                    <div className="pb-4 d-flex gap-3 flex-wrap socials-icons">
                            <a target="_blank" rel="noreferrer" href='https://www.facebook.com/euodootech/' className='flex items-center'>
                                <i  className="fa-brands fa-facebook-square "></i>
                            </a>
                            <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@euodootech' className='flex items-center'>
                                <i className="fa-brands fa-youtube text-2xl"></i>
                            </a>
                            <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/13650727/admin/dashboard/' className='flex items-center'>
                                <i className="fa-brands fa-linkedin text-2xl"></i>
                            </a>
                            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/euodootechinc/' className='flex items-center'>
                                <i className="fa-brands fa-instagram text-2xl"></i>
                            </a>
                            <a target="_blank" rel="noreferrer" href='https://www.tiktok.com/@euodootechnologies?lang=en' className='flex items-center'>
                                <i className="fa-brands fa-tiktok text-2xl"></i>
                            </a>
                            <a target="_blank" rel="noreferrer" href='https://twitter.com/EuodooTech' className='flex items-center'>
                                <i className="fa-brands fa-twitter text-2xl"></i>
                            </a>
                    </div>
                </div>
            </div>
        </div>
        
    )

}

export default Footer;